import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link } from "gatsby"
import notFound from '../images/404/404.svg';

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div className="ui-hero hero-lg ui-gradient-blue hero-svg-layer-3" >
      <div data-fade_in="on-load" className="error404-page">
        <div className="main" role="main">

          <div className="error404-wrappe text-centerr">
            <img src={notFound} alt="System Code"/>
            <h1 className="heading">Algo errado aconteceu...</h1>
            <p className="lead mb-3">A página que você procura não existe...</p>
            <Link to="/" className="btn ui-gradient-blue">Página Inicial</Link>
          </div>

        </div>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
